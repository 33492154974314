import { Group, GroupProps, Title } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  title: string;
  subTitle?: string;
  rightSection?: ReactNode;
}

const PageHeaderTitle = ({ title, subTitle, rightSection, ...groupProps }: Props & GroupProps) => {
  return (
    <Group position="apart" mb={16} {...groupProps}>
      <Group spacing={32}>
        <Title order={2}>{title}</Title>
        {!!subTitle && <Title order={3}>{subTitle}</Title>}
      </Group>
      {rightSection}
    </Group>
  );
};
export default PageHeaderTitle;
