import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, PasswordInput, Space, TextInput } from "@mantine/core";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useAuth } from "@/contexts/AuthContext";
import { zRequiredString } from "@/utils/zodValidations";

const loginErrorMessage = "メールアドレス、電話番号またはパスワードが正しくありません";
const schema = z.object({
  uid: zRequiredString(loginErrorMessage),
  password: zRequiredString(loginErrorMessage),
});
type FormValues = z.infer<typeof schema>;

const Form = ({ token }: { token?: string }) => {
  const { login } = useAuth();
  const router = useRouter();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      uid: "",
      password: "",
    },
  });

  const onSubmit = handleSubmit(async (params) => {
    const errorMessage = await login(params.uid, params.password, token);
    if (errorMessage) {
      setError("uid", { message: loginErrorMessage });
      setError("password", { message: loginErrorMessage });
      return;
    }
    router.push("/");
  });

  const hasError = useMemo(() => {
    return !!(errors.password || errors.uid);
  }, [errors.password, errors.uid]);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="uid"
        render={({ field }) => (
          <TextInput
            label="メールアドレス or 電話番号"
            placeholder="メールアドレス or 電話番号を入力"
            error={hasError}
            {...field}
          />
        )}
      />
      <Space h={24} />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <PasswordInput
            label="パスワード"
            placeholder="半角英数字10文字以上"
            error={hasError}
            {...field}
          />
        )}
      />
      {hasError && (
        <Input.Error mt={24} size="md" sx={{ textAlign: "center" }}>
          {loginErrorMessage}
        </Input.Error>
      )}
      <Space h={24} />
      <Button type="submit" sx={{ width: "100%" }} loading={isSubmitting}>
        ログイン
      </Button>
    </form>
  );
};

export default Form;
