import { COLORS } from "@/styles/colors";

import { faCheckCircle, faCircleXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationProps, showNotification } from "@mantine/notifications";

export const showErrorNotification = (
  message: string,
  options: Partial<NotificationProps> = {}
) => {
  showNotification({
    title: message,
    message: "",
    icon: (
      <FontAwesomeIcon
        icon={faCircleXmark}
        style={{
          width: "100%",
          height: "100%",
          color: COLORS.red,
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      />
    ),
    styles: {
      root: {
        backgroundColor: COLORS.redPale,
        borderColor: COLORS.redPale,
      },
      title: { color: COLORS.red },
    },
    ...options,
  });
};

export const showSuccessNotification = (
  message: string,
  options: Partial<NotificationProps> = {}
) => {
  showNotification({
    title: message,
    message: "",
    icon: (
      <FontAwesomeIcon
        icon={faCheckCircle}
        style={{
          width: "100%",
          height: "100%",
          color: COLORS.green,
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      />
    ),
    styles: {
      root: {
        backgroundColor: COLORS.greenPale,
        borderColor: COLORS.greenPale,
        "&::before": { backgroundColor: COLORS.white },
      },
      title: { color: COLORS.green },
    },
    ...options,
  });
};

export const showCommonNotification = (
  message: string,
  options: Partial<NotificationProps> = {}
) => {
  showNotification({
    title: message,
    message: "",
    icon: (
      <FontAwesomeIcon
        icon={faInfoCircle}
        style={{
          width: "100%",
          height: "100%",
          color: COLORS.gray400,
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      />
    ),
    ...options,
  });
};
