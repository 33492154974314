import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Group, Paper, Space, Text } from "@mantine/core";
import { GetServerSideProps, NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

import PageHeaderTitle from "@/components/molecules/PageHeaderTitle";
import Form from "@/features/signIn/components/Form";
import { parseServerAuth, useClientAuth } from "@/gateways/authStore";
import { showSuccessNotification } from "@/utils/notification";

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const auth = parseServerAuth(ctx);
  if (auth) {
    return {
      redirect: {
        permanent: false,
        destination: "/",
      },
    };
  }
  return {
    props: {},
  };
};

const SignIn: NextPage = () => {
  const router = useRouter();
  const { removeAuth } = useClientAuth();

  const token = useMemo(() => {
    if (!router.isReady) return;
    return router.query.t as string | undefined;
  }, [router.isReady, router.query.t]);
  useEffect(() => {
    removeAuth();
  }, [removeAuth]);

  useEffect(() => {
    if (router.query.alreadyRegistered) {
      showSuccessNotification("すでに登録が完了しています");
    }
  }, [router.query]);

  return (
    <>
      <PageHeaderTitle title="ログイン" />
      <Paper shadow="sm" p={16} mb={48}>
        <Form token={token} />
      </Paper>

      <Anchor component={Link} href="/password/new">
        <Group spacing={8} position="center">
          <FontAwesomeIcon icon={faQuestionCircle} />
          <Text size="sm">パスワードを忘れた方はこちら</Text>
        </Group>
      </Anchor>
      <Space h={48} />

      <Text size="sm">アカウントをお持ちでない方は管理者に招待を依頼してください</Text>
    </>
  );
};

export default SignIn;
