import { z } from "zod";

export const zEmail = (options?: { optional: boolean }) => {
  const schema = z
    .string({ required_error: "メールアドレスを入力してください" })
    .email({ message: "正しいメールアドレスの形式で入力してください" });
  return options?.optional ? schema.optional().or(z.literal("")) : schema;
};

export const zMobilePhoneNumber = (options?: { optional: boolean }) => {
  if (options?.optional) {
    return z
      .string()
      .regex(/^[^-]*$/, "ハイフンを含まずに半角数字のみで入力してください")
      .regex(/^(0\d{9,10}|)$/, "正しい電話番号を入力してください")
      .optional();
  }
  return z
    .string({ required_error: "電話番号を入力してください" })
    .regex(/^[^-]*$/, "ハイフンを含まずに半角数字のみで入力してください")
    .regex(/^0\d{9,10}$/, "正しい電話番号を入力してください");
};

export const zPassword = (requiredMessage = "半角英数字10文字以上で入力してください") =>
  z
    .string({ required_error: requiredMessage })
    .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, { message: "半角英数字10文字以上で入力してください" })
    .min(10, { message: "半角英数字10文字以上で入力してください" });

export const zKatakana = (options?: { optional: boolean }) => {
  const regexOptions = { message: "全角カナで入力してください" };
  if (options?.optional) {
    return z
      .string()
      .trim()
      .regex(/^[ァ-ヴー\s]*$/, regexOptions)
      .optional();
  } else {
    return z
      .string({ required_error: "入力してください" })
      .trim()
      .regex(/^[ァ-ヴー\s]+$/, regexOptions);
  }
};

export const zRequiredString = (message = "入力してください") => {
  return z.string({ required_error: message }).trim().min(1, { message });
};

export const zFile = typeof window === "undefined" ? z.any() : z.instanceof(File);

export const zRequiredFile = (message = "ファイルを選択してください") => {
  return typeof window === "undefined" ? z.any() : z.instanceof(File, { message });
};
